@media screen and (max-width:600px) {
  .closeBtn {
    height: 30px;
    width: 30px;
    opacity: 0.75;
    position: absolute;
    top: 20px; left: 20px;
    background-color: #FFFFFF;
    border-radius: 50px;
    border: none;
    padding: 0;
  }

  .iconX {
    color: #000;
    margin-top: 2.5px;
  }
  
}

@media screen and (min-width: 768px) {
  .closeBtn {
    height: 30px;
    width: 100px;
    opacity: 0.75;
    background-color: #FFFFFF;
    border: none;
    padding: 0;
  }

  .iconX {
    color: #000;
    margin-top: 2.5px;
  }
  
}