@media screen and (min-width: 768px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;
        /* border: 1px solid #ddd; */
        
    }

    .Logo img {
        width: 430px;
        height: 267px;
        margin: -100px -100px -100px -100px;
        
    }

}