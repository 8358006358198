@media screen and (max-width:600px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
      background-color: #ffffff;
      /* border: solid 1px black; */
      position:fixed;
      top:0;
      left:0;
      z-index: 99;
  }

  .navback {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .navtitle h1 {
    font-size: 15px;
  }

  .navrightside {
    height: 30px;
    width: 30px;
    margin-right: 20px;
  }

  .btnReset {
    border: none;
    background-color: #ffffff;
  }
}

@media screen and (min-width: 768px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
      background-color: #ffffff;
  }

  .navback {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .navtitle h1 {
    font-size: 20px;
  }

  .navrightside {
    height: 30px;
    width: 30px;
    margin-right: 20px;
  }

  .btnReset {
    border: none;
    background-color: #ffffff;
  }
}