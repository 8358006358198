/* responsive
@media screen and (max-width: 600px)  { } > pour les smartphones
@media screen and (min-width: 768px) { } > pour les écrans d'ordinateur
*/
.emptyListNotification{
  left:20px;
  width: 100%;
  /* height: 80px; */
  border-radius: 2%;
  margin-left: auto;
  margin-right: auto;
  background: #F8F8F8;
  opacity: 0.9;
  border-radius: 15px;
  border : solid 1px;
  border-color: #e7e7e7;
}



@media screen and (max-width: 600px)  { 
    .padTxt {
      padding: 2px 10px;
    }

    .btnParticiper {
      padding: 5px 30px;
      border-radius: 50px;
      font-size: 18px;
      height: 45px;
      font-family: 'Roboto', sans-serif;
      background-color: #000;
      color: #fff;
      border: none;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 768px) {
    .padTxt {
      padding: 2px 10px;
    }

    .btnParticiper {
      padding: 5px 30px;
      border-radius: 50px;
      font-size: 18px;
      height: 45px;
      font-family: 'Roboto', sans-serif;
      background-color: #000;
      color: #fff;
      border: none;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
 }


/*event tirage au sort rentree 2023*/


/* 
##       ######## ##     ## ####  #######  ##     ## ######## 
##       ##        ##   ##   ##  ##     ## ##     ## ##       
##       ##         ## ##    ##  ##     ## ##     ## ##       
##       ######      ###     ##  ##     ## ##     ## ######   
##       ##         ## ##    ##  ##  ## ## ##     ## ##       
##       ##        ##   ##   ##  ##    ##  ##     ## ##       
######## ######## ##     ## ####  ##### ##  #######  ########  */

/* police */
.useRoboto { font-family: 'Roboto', sans-serif }
.roboto  { font-family: 'Roboto', sans-serif }
.aReset { text-decoration: none }
.btnReset {border: none;}

/* taille de police */
.txt9 { font-size: 9px }
.txt11 { font-size: 11px }
.txt12 { font-size: 12px }
.txt13 { font-size: 13px }
.txt15 { font-size: 15px }
.txt16 { font-size: 16px }
.txt18 { font-size: 18px }
.txt20 { font-size: 20px }
.txt22 { font-size: 22px }
.txt25 { font-size: 25px }
.txt30 { font-size: 30px }
.txt35 { font-size: 35px }
.txt40 { font-size: 40px }
.txt70 { font-size: 70px }

/* couleur du texte */
.bT { color: #000 }
.wT { color: #ffffff }
.lGreyT { color : #ededed }
.dGreyT { color:#818181 }
.terracotaT { color: #CC4E3C }
.greenT { color: #1FA200 }
.rT { color : #FF0000 }
.unisT { color : #2d2378}

/* style du texte */
.bold { font-weight: bold }
.medium { font-weight: 500 }
.mediumplus { font-weight: 700 }
.thin { font-weight: 200 }
.capitals { text-transform: uppercase }
.majMin { text-transform: capitalize }
.italic { font-style: italic}
.underline {text-decoration: underline }
.underlineOffset3 {text-underline-offset: 3px}
.jumpline {white-space: normal}
.cap:first-letter{
  text-transform: uppercase;
}

/*txt deco*/
 .upperC {text-transform: uppercase;}
 .finalDots {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
  }

  .finalDotsMinus {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
  }

  .finalDotsMed {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 220px;
  }

/* alignement du texte */
.txtCenter { text-align: center }
.txtJustify { text-align: justify }
.lineHmin { line-height: 1px }
.lineH10 { line-height: 10px }
.lineH13 { line-height: 13px }
.lineH15 { line-height: 15px }
.lineH18 { line-height: 18px }
.lineH20 { line-height: 20px }
.lineH25 { line-height: 25px }
.lineH40 { line-height: 40px }
.fix {position : fixed}

/* backgrounds colors */
.bLiteTransparent { background-color: #ffffff ; opacity: 0.7 }
.bLiteGrey { background-color: #F8F8F8 }
.bDarkGrey { background-color: #A1A1A1 }
.bBlack { background-color: #000 }
.bWhite { background-color: #ffffff }
.bGreen { background-color: #1FA200 }
.bLiteGreen { background-color: #d6f9ce }
.bTerracota { background-color: #CC4E3C }

/*borderstyle */
.bordTerracota { border: 1px solid #CC4E3C }
.bordLiteGrey { 
  width: 100%;
  height:  1px;
  background-color: #F8F8F8; 
  border: none }
.topBorder {
  border-top: 2px solid rgb(216, 216, 216)
}

.leftBorder {
   border-left: 1px solid #EBEBEB;
}

/* alignement de block */
.width20pCenter { width: 20%; margin-left: auto; margin-right: auto }

.width30pCenter { width: 25%; margin-left: auto; margin-right: auto }
.width40pCenter { width: 40%; margin-left: auto; margin-right: auto }
.width50pCenter { width: 50%; margin-left: auto; margin-right: auto }
.width90pCenter { width: 90%; margin-left: auto; margin-right: auto }
.width95pCenter { width: 95%; margin-left: auto; margin-right: auto }

.horizontal { display: flex }
.sameWidth { flex: 1 1 0 }
.vertical { display: flex; flex-direction: column }
.iStart { align-items: start }
.iCenter { align-items: center }
.iBtm { align-items: baseline }
.jCenter { justify-content: center }
.jStart { justify-content: flex-start }
.jEnd { justify-content: flex-end }
.iEnd { align-items: end }
.spaceBtw { justify-content: space-between }
.spaceArd { align-items: center; justify-content: space-around }
.wraped { flex-wrap: wrap }

/* padding */
.noPdg { padding: 0}

/* margin */
.noMrg { margin: 0 }
.noMrgB { margin-bottom: 0 }
.noMrgT { margin-top: 0 }
.noMrgTB { margin-top: 0; margin-bottom: 0 }

.mrgT5 { margin-top: 5px }
.mrgT8 { margin-top: 8px }
.mrgT10 { margin-top: 10px }
.mrgT20 { margin-top: 20px }
.mrgT50 { margin-top: 50px }
.mrgT100 { margin-top: 100px }
.mrgT150 { margin-top: 150px }
.mrgT200 { margin-top: 200px }
.mrgTauto { margin-top: auto }

.mrgR2 { margin-right: 2px }
.mrgR5 { margin-right: 5px }
.mrgR10 { margin-right: 10px }
.mrgR20 { margin-right: 20px }
.mrgR40 { margin-right: 40px }
.mrgR50 { margin-right: 50px }
.mrgR100 { margin-right: 60px }
.mrgRauto { margin-right: auto }


#mrgB2 { margin-bottom: 2px }
.mrgB5 { margin-bottom: 5px }
.mrgB7 { margin-bottom: 7px }
.mrgB10 { margin-bottom: 10px }
.mrgB20 { margin-bottom: 20px }
.mrgB30 { margin-bottom: 30px }    
#mrgB50 { margin-bottom: 50px }
#mrgB75 { margin-bottom: 75px }
#mrgB100 { margin-bottom: 100px }
.mrgB100 { margin-bottom: 100px }
.mrgB150 { margin-bottom: 150px }
.mrgB200 { margin-bottom: 200px }

.mrgL3 { margin-left: 3px }
.mrgL5 { margin-left: 5px }
.mrgL7 { margin-left: 7px }
.mrgL10 { margin-left: 10px }
.mrgL15 { margin-left: 15px }
.mrgL30 { margin-left: 30px }
.mrgL20 { margin-left: 20px }
.mrgL40 { margin-left: 40px }
.mrgLauto { margin-left: auto }

/*padding*/
.paddingBox { padding: 6px 5px 1px 5px}
.padR40 { padding-right: 40px}
.padL40 { padding-left: 40px}

/* images */
.img50x50 { width: 50px; height: 50px; border:1px solid #CFD8DC; background-color: #FFFFFF; }
.img58x58 { width: 58px; height: 58px; border:1px solid #CFD8DC; border-radius: 4px; background-color: #FFFFFF; }
.img65x65 { width: 65px; height: 65px; border:1px solid #CFD8DC; border-radius: 4px; background-color: #FFFFFF; }
.img68x68 { width: 68px; height: 68px; border:1px solid #CFD8DC; border-radius: 4px; background-color: #FFFFFF; }
.img68x68T { width: 68px; height: 68px; border:1px solid #CFD8DC; border-radius: 4px }
.img100x100 { width: 100px; height: 100px; border:1px solid #CFD8DC; border-radius: 4px; background-color: #FFFFFF; }
.img100x100T { width: 100px; height: 100px; border:1px solid #CFD8DC; border-radius: 4px }

.roundImg100 {
  width: 110px; height: 110px; 
  border: none; border-radius: 100px;
  margin: 0px;
  border:1px solid #CFD8DC;
  background-color: #ededed;
}

.roundImg70 {
  width: 70px; height: 70px; 
  border: none; border-radius: 50px;
  margin: 0px;
  border:1px solid #CFD8DC;
  background-color: #ededed;
}

.roundImg60 {
  width: 60px; height: 60px; 
  border: none; border-radius: 50px;
  margin: 0px;
  border:1px solid #CFD8DC;
  background-color: #ededed;
}

.roundImg50 {
  width: 50px; height: 50px; 
  border: none; border-radius: 50px;
  margin: 0px;
  border:1px solid #CFD8DC;
  background-color: #ededed;
}

.bwImg {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}

.mantine-xiucpp {
  padding: 0px;
}

@media screen and (max-width: 600px)  {

.w25 {
  width: 25%;
}

.w50 {
  width: 50%;
}

.w80 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.w90 {
  max-width: 80%;
}

.carrouselLogoImg {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
}


.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.w70p {
  max-width: 70px
}


.w300p {
  max-width: 300px;
}


/*btn*/
.sBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 15px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #FFFFFF;
}

.deleteBtn {
  font-size: 13px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #FF0000;
  border: none;
}
.basicBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 15px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #FFFFFF;
  color: #000;
}
.pBtn {
  border-radius: 4px;
  border: 1px solid #CC4E3C;
  font-size: 15px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #CC4E3C;
  color: #FFFFFF;
}
.callBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 13px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #FFFFFF;
  padding: 5px 10px;
}

@media screen and (max-width: 600px)  {
  .imgDeleteImgBtnAbsolute {
    position : absolute;
    right: 0px;
    padding: 0px;
    border-radius: 5px;
    height: 14px;
  }
  .imgRelative {
    position: relative;
  }

  .linkToDocsBtn {
    border-radius: 4px;
    border: 1px solid #dedede;
    font-size: 13px;
    margin-left: auto;
    font-family: 'Roboto', sans-serif;
    background-color: #dedede;
    color: #000;
    padding: 9px 3px;
    height: 50%;
  }

  .linkToDocsInput {
    width: 75%;
    margin-right: 10px;
  }

}



}

@media screen and (min-width: 768px)  {
  /*width*/
.w25 {
  width: 25%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

  .w80 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.carrouselLogoImg {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
}


.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.w70p {
  max-width: 70px
}


.w300p {
  max-width: 300px;
}


/*btn*/
.sBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 18px;
  width: 100%;
  height: 50px;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #FFFFFF;
}
.deleteBtn {
  font-size: 18px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #FF0000;
  border: none;
}
.basicBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 15px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #FFFFFF;
  color: #000;
}
.pBtn {
  border-radius: 4px;
  border: 1px solid #CC4E3C;
  font-size: 15px;
  width: 100%;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  background-color: #CC4E3C;
  color: #FFFFFF;
}
.callBtn {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 16px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #FFFFFF;
  padding: 8px 14px;
}

.linkToDocsBtn {
  border-radius: 4px;
  border: 1px solid #dedede;
  font-size: 16px;
  margin-left: auto;
  font-family: 'Roboto', sans-serif;
  background-color: #dedede;
  color: #000;
  padding: 9px 10px;
  height: 53%;
}

.linkToDocsInput {
  width: 85%;
  margin-right: 10px;
}

}

@media screen and (max-width: 600px)  {
  .imgDeleteImgBtnAbsolute {
    position : absolute;
    right: 0px;
    padding: 0px;
    border-radius: 5px;
    height: 14px;
  }
  .imgRelative {
    position: relative;
  }
  }

@media screen and (min-width: 768px) { 
  .imgDeleteImgBtnAbsolute {
    position : absolute;
    right: 0px;
    padding: 0px;
    border-radius: 5px;
    height: 20px;
  }
  .imgRelative {
    position: relative;
  }

}
@media screen and (max-width: 600px)  {
/* boutons SYNDIC dans onglet Offre de travaux, composant : OffresSyndic*/
 #btn-urgent-off {
    border: 0;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: medium;
   }

   #btn-urgent-on {
    border: 0;
    background-color: #FFFFFF;
    color: #FF0000;
   }

/*btn filtre SYNDIC filtre icon */
   .bordLiteGreySimple { 
      border: 1px solid #F8F8F8;
      border-radius: 5px;
      width: 20%;
  }
 
}


/* 
##    ##    ###    ##     ## ########     ###    ########   ######  
###   ##   ## ##   ##     ## ##     ##   ## ##   ##     ## ##    ## 
####  ##  ##   ##  ##     ## ##     ##  ##   ##  ##     ## ##       
## ## ## ##     ## ##     ## ########  ##     ## ########   ######  
##  #### #########  ##   ##  ##     ## ######### ##   ##         ## 
##   ### ##     ##   ## ##   ##     ## ##     ## ##    ##  ##    ## 
##    ## ##     ##    ###    ########  ##     ## ##     ##  ######  */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* navbar page vitrine*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
.hidden {
  display: none;
}
@media screen and (max-width: 600px)  {
  .navbar {
    overflow: hidden;
    background-color: white;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; 
    padding-bottom: 10px;
    display: flex;
    z-index: 20;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  }

  .mantine-1nbod7e {
    position: inherit;
  }
 
  .logo {
    margin-top: 14px;
  }
  
  .logo a {
      color: #282c34;
      font-weight: bold;
      font-size: 25px;
      text-decoration: none;
  }

  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    width: 310px;
    height: 100%;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    background-color: #fdfdfd;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: 0;
  }

    .logo_item {
      font-size: 25px;
      font-weight: bold;
      position: absolute;
      top: 14px;
      left: 25px;
    }

   .menu__item {
    display: block;
    padding: 12px 24px;
    color: #282c34;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }

  .menu__item:hover {
    background-color: #CFD8DC;
    color: #282c34;
  }  
}

@media screen and (min-width: 768px)  {
  .navbar {
    overflow: hidden;
    background-color: white;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; 
    padding-bottom: 10px;
    display: flex;
    z-index: 20;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  }
 
  .logo {
    margin-top: 14px;
  }
  
  .logo a {
      color: #282c34;
      font-weight: bold;
      font-size: 25px;
      text-decoration: none;
  }

  
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    width: 450px;
    height: 100%;
    margin: 0;
    padding: 0px;
    list-style: none;
    background-color: #fdfdfd;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: 21;
  }

    .logo_item {
      font-size: 25px;
      font-weight: bold;
      position: absolute;
      top: 14px;
      left: 25px;
    }

   .menu__item {
    display: block;
    margin-left: 10px;
    padding: 15px 0px 15px 30px;
    color: #282c34;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }

  .logo_size {
    width: 35px;
  }

  .menu__item:hover {
    background-color: #CFD8DC;
    color: #282c34;
  }  
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* navbar de l'application*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/

@media screen and (max-width: 600px) {
  .app-navbar {
    background-color: #ffffff;
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    text-align: center;
    text-indent: 0px; /*décalage du texte vers la droite*/
    text-decoration: none;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    height: 86px
  }

  #app-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: 10px;
  }

  .app-navbar p {
      margin-top: 5px;
  }

  #not-selected {
    color: #F8F8F8;
    background-color: #F8F8F8;
    opacity: 0.33;
  }

  #a-not-selected {
    color: #B1B1B1
  }

  #selected {
    color: #000;
    background-color: #FFFFFF;
    border: 2px solid #CC4E3C
  }

  #a-selected {
    color: #CC4E3C;
  }

  .app-navbar ul {
    list-style-type: none;
  }

  /* .app-navbar li {
    text-decoration: none;
  } */

 .app-navbar a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 11px;
    color: #282c34;
    font-weight: 500;
  }

  .app-navbar a.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 11px;
    color: #CC4E3C;
    font-weight: 500;
  }
} 

@media screen and (min-width: 768px) {

.appMenuBorder {
  border-bottom: solid 1px #e5e5e5;
}

.wMenu {
  width: 330px;
}

.app-navbar a.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #CC4E3C;
  font-weight: 500;
  border-bottom: 1px solid #CC4E3C;
}

}

/* 
########     ###     ######   ########  ######  
##     ##   ## ##   ##    ##  ##       ##    ## 
##     ##  ##   ##  ##        ##       ##       
########  ##     ## ##   #### ######    ######  
##        ######### ##    ##  ##             ## 
##        ##     ## ##    ##  ##       ##    ## 
##        ##     ##  ######   ########  ######  */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* homepage*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
.hrHomeForm {
  width: 30%;
  color: #d8d8d8;
  background-color: #d8d8d8;
  border: none;
  height: 1px;
}

.hrMaintenanceMsg {
  width: 50%;
  color: #d8d8d8;
  background-color: #d8d8d8;
  border: none;
  height: 1px;
}
@media screen and (max-width: 600px)  {

  #homePageSignUpButton {
    border-radius: 50px;
    font-size: 18px;
    width: 70%;
    margin-left: auto; margin-right: auto;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    background-color: #CC4E3C;
    color: #fff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  #homePageSignUpButton50w {
    border-radius: 50px;
    font-size: 16px;
    width: 50%;
    margin-left: auto; margin-right: auto;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    background-color: #CC4E3C;
    color: #fff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  #homePageLinkToLogin {
    border-radius: 50px;
    font-size: 15px;
    width: 90%;
    margin-left: auto; margin-right: auto;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    background-color: #000;
    color: #ffffff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  .imgUnisHome { 
    width: 30%;
    height: 30%;
    background-color: #FFFFFF;
  }

  .imgFranceInnovationHome { 
    width: 40%;
    height: 30%;
    background-color: #FFFFFF;
  }


} 

@media screen and (min-width: 768px) {

  #homePageSignUpButton {
    border-radius: 50px;
    width: 30%;
    margin-left: auto; margin-right: auto;
    padding-left: 20px; padding-right: 20px;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    background-color: #CC4E3C;
    color: #fff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  #homePageSignUpButton50w {
    border-radius: 50px;
    font-size: 18px;
    width: 25%;
    margin-left: auto; margin-right: auto;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    background-color: #CC4E3C;
    color: #fff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  #homePageLinkToLogin {
    border-radius: 50px;
    font-size: 22px;
    width: 50%;
    margin-left: auto; margin-right: auto;
    padding-left: 20px; padding-right: 20px;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    background-color: #000;
    color: #ffffff;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  .imgUnisHome { 
    width: 19%;
    height: 30%;
    background-color: #FFFFFF;
  }

  .imgFranceInnovationHome { 
    width: 28%;
    height: 30%;
    background-color: #FFFFFF;
  }

}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* SignUp et Login*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/

@media screen and (max-width: 600px) {
  #signUpTitle {
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 0;
  }

  #signUpSubtitle {
    text-align: center;
    font-size: 15px;
  }

  #noteRGDP {
    font-size: 13px;
    color: #616161;
  }
  
/* style de la phrase vers connexion au login */
  #loginSubtitle {
    font-size: 13px ;
    text-align: center;
  }
  
}

@media screen and (min-width: 768px) {
  #signUpTitle {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 0;
  }

  #signUpSubtitle {
    text-align: center;
    font-size: 20px;
  }

  #noteRGDP {
    font-size: 16px;
    color: #616161;
    text-align: justify;
  }
  
/* style de la phrase vers connexion au login */
  #loginSubtitle {
    font-size: 20px ;
    text-align: center;
  }
  
  .mantine-15qu4e0 {
    max-width: 400px;
  }



}


/*
########  #######   #######  ######## ######## ########  
##       ##     ## ##     ##    ##    ##       ##     ## 
##       ##     ## ##     ##    ##    ##       ##     ## 
######   ##     ## ##     ##    ##    ######   ########  
##       ##     ## ##     ##    ##    ##       ##   ##   
##       ##     ## ##     ##    ##    ##       ##    ##  
##        #######   #######     ##    ######## ##     ## */

@media screen and (max-width: 600px)  {
  .footer{
    height: 500px;
    color: #282c34;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 100px;
  }

  .footer-align{
    display: flex;
    flex-direction: column;
    align-items: top;
    padding-top: 10px;
    margin-left: 30px;
  }

  .footer-align h3{
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .footer-align ul{
    list-style: none;
  }
  
  .footer-align li{
    list-style: none;
    padding-bottom: 8px;
    line-height: 12px;
  }

  .footer-align a{
    color: #282c34;
    text-decoration: none;
    font-weight: 400;
    font-size: 13px;
  }
  
  .footer p{
    margin-top: 100px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 0.8em;
  }
}

@media screen and (min-width: 768px) {  .footer{
    height: 330px;
    color: #282c34;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 150px;
    padding-top: 30px;
  }

  .footer-align{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    align-items: top;
    padding-top: 10px;
    margin-left: 30px;
  }

  .footer-align h3{
    font-size: 25px;
    margin-bottom: 10px;
  }
  
  .footer-align ul{
    list-style: none;
  }
  
  .footer-align li{
    list-style: none;
    padding-bottom: 8px;
    line-height: 20px;
  }

  .footer-align a{
    color: #282c34;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
  }
  
  .footer p{
    margin-top: 100px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 0.8em;
  }
}







/*
 ######  ##    ## ##    ## ########  ####  ######  
##    ##  ##  ##  ###   ## ##     ##  ##  ##    ## 
##         ####   ####  ## ##     ##  ##  ##       
 ######     ##    ## ## ## ##     ##  ##  ##       
      ##    ##    ##  #### ##     ##  ##  ##       
##    ##    ##    ##   ### ##     ##  ##  ##    ## 
 ######     ##    ##    ## ########  ####  ######  */



/*---------------------------------------------------------------------------*/
/* Onglet Offre de travaux > Formulaire ajout d'une nouvelle offre */
/*---------------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
 /* proportions entre la ligne num et adresse */
  #xsWidth { width: 22% }
  #smWidth { width: 75% }

  #sWidth { width: 38% }
  #mWidth { width: 60% }

  #hrForm {
    width: 30%;
    margin-top: 20px;
    margin-bottom: 20px
  }

  #stepForm-mrg-t { margin-top: 20px }

  /* customisation de mantine du formulaire */
  /* texte de la barre des étapes */
  .mantine-1kns4fk {
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    color: #282c34;
  }
/* bulles d'étape par défaut */
  .mantine-132qhyl {
    background-color: #ffffff;
  }

 /* bulles d'étape en cours */ 
  .mantine-132qhyl[data-progress] {
    border-color: #CC4E3C;
    background-color: #f8f8f8;
  }

 /* bulles d'étape fait */ 
  .mantine-132qhyl[data-completed] {
    background-color: #282c34;
    border-color: #282c34;
  }

  /* libélés des inputs */
  .mantine-ittua2 {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: bold;
  }

/* placeholder du métier */
  .mantine-ye2q1m {
    font-family: 'Roboto', sans-serif;
  }

/* placeholder du titre */
  .mantine-1g14e8k {
    font-family: 'Roboto', sans-serif;
  }

  /* libélés des checkbox */
  .mantine-upmgn3 {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin: 5px 0;
    line-height: 20px;
  }

  /* positionnement de la case à cocher */
  .mantine-ginko4 {
    margin: 5px 0;
  }
  .mantine-ginko4:checked+.__mantine-ref-icon {
    margin: 12px 5px;
  }

  /* texte description délai reception devis */
  .mantine-1v8p0ef {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
  }

  /* placeholder delai reception devis */
  .mantine-1y9bl4z {
    font-family: 'Roboto', sans-serif;
  }

  /* placeholder de tous les champs adresse */
  .mantine-rum3t1 {
    font-family: 'Roboto', sans-serif;
  }

  /* placehoder du champs des instructions */
  .mantine-1cp6ct5 {
    font-family: 'Roboto', sans-serif;
  }

   /* placehoder ajouter photo */
  .mantine-broq8b {
    font-family: 'Roboto', sans-serif;
  }

  /*modification d'une offre*/
  /*affichage des photos qu'on peut supprimer*/
  .mantine-h9iq4m {
    padding-top: 2px;
  }

  /**/
  .mantine-1sss667 {
    margin-left: auto;
  }
  /*checkbox font*/
  .mantine-fnunf0 {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 14px;
    font-weight: 600;
    padding-right: inherit;
    line-height: 15px;
  }

  /*bouton ajouter photo*/
  .mantine-1ryt1ht {
    overflow: inherit;
    white-space: inherit;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

}

@media screen and (min-width: 768px) {
  /* proportions entre la ligne num et adresse */
   #xsWidth { width: 22% }
   #smWidth { width: 75% }
 
   #sWidth { width: 38% }
   #mWidth { width: 60% }
 
   #hrForm {
     width: 30%;
     margin-top: 20px;
     margin-bottom: 20px
   }
 
   #stepForm-mrg-t { margin-top: 20px }
 
   /* customisation de mantine du formulaire */
   /* texte de la barre des étapes */
   .mantine-1kns4fk {
     font-family: 'Roboto', sans-serif;
     font-size: 11px;
     color: #282c34;
   }
 /* bulles d'étape par défaut */
   .mantine-132qhyl {
     background-color: #ffffff;
   }
 
  /* bulles d'étape en cours */ 
   .mantine-132qhyl[data-progress] {
     border-color: #CC4E3C;
     background-color: #f8f8f8;
   }
 
  /* bulles d'étape fait */ 
   .mantine-132qhyl[data-completed] {
     background-color: #282c34;
     border-color: #282c34;
   }
 
   /* libélés des inputs */
   .mantine-ittua2 {
     font-family: 'Roboto', sans-serif;
     font-size: 15px;
     font-weight: bold;
   }
 
 /* placeholder du métier */
   .mantine-ye2q1m {
     font-family: 'Roboto', sans-serif;
   }
 
 /* placeholder du titre */
   .mantine-1g14e8k {
     font-family: 'Roboto', sans-serif;
   }
 
   /* libélés des checkbox */
   .mantine-upmgn3 {
     font-family: 'Roboto', sans-serif;
     font-size: 15px;
     font-weight: bold;
     margin: 5px 0;
     line-height: 20px;
   }
 
   /* positionnement de la case à cocher */
   .mantine-ginko4 {
     margin: 5px 0;
   }
   .mantine-ginko4:checked+.__mantine-ref-icon {
     margin: 12px 5px;
   }
 
   /* texte description délai reception devis */
   .mantine-1v8p0ef {
     font-family: 'Roboto', sans-serif;
     font-size: 13px;
   }
 
   /* placeholder delai reception devis */
   .mantine-1y9bl4z {
     font-family: 'Roboto', sans-serif;
   }
 
   /* placeholder de tous les champs adresse */
   .mantine-rum3t1 {
     font-family: 'Roboto', sans-serif;
   }
 
   /* placehoder du champs des instructions */
   .mantine-1cp6ct5 {
     font-family: 'Roboto', sans-serif;
   }
 
    /* placehoder ajouter photo */
   .mantine-broq8b {
     font-family: 'Roboto', sans-serif;
   }
 
   /*modification d'une offre*/
   /*affichage des photos qu'on peut supprimer*/
   .mantine-h9iq4m {
     padding-top: 2px;
   }
 
   /**/
   .mantine-1sss667 {
     margin-left: auto;
   }
   /*checkbox font*/
   .mantine-fnunf0 {
     /* font-family: 'Roboto', sans-serif; */
     font-size: 14px;
     font-weight: 600;
     padding-right: inherit;
     line-height: 15px;
   }
 
   /*bouton ajouter photo*/
   .mantine-1ryt1ht {
     overflow: inherit;
     white-space: inherit;
     font-size: 13px;
     text-align: center;
     display: flex;
     justify-content: center;
   }
 
 }

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Liste des offres */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
  .badge-container {
    display: flex;
    align-items: center;
  }

  
  .text-following-badges {
    margin-top: 20px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .badge-container {
    display: flex;
    align-items: center;
  }

  .text-following-badges {
    margin-top: 20px;
    width: 100%;
  }
}
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Component OfferCard */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
  /* logo du syndic superposéer la photo du caroussel */
  #syndic-logo-OffreDeTravaux {
    height: 40px;
    position: relative;
    top:-100px; right: -5px;
    padding: 5px 15px;
  }

  .noLogoOnPic {
    width: 40px;
    height: 40px;
    position: relative;
    top:-100px; right: -10px;
    padding: 5px 15px;
    border: 1px solid #ededed;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    color:#393939;
    font-size: 30px;
    font-weight: 400;
  }

    .redbox {
      width: 95%;
      padding: 10px 5px;
      background-color: #f8f8f8;
      border: solid 1px #CC4E3C;
      margin-left: auto;
      margin-right: auto;
      border-radius: 4px;
    }

  .imgCarroussel {
    width: 100%;
    height: 450px
  }

  .offer-first-lines-info {
    width: 90%;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: auto;
    position: relative;
    top:-60px; left: 0px
  }

  .Offer-card-container p {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0
  }

  /*fait remonter le reste de la page qui contient les box*/
  #positionUp {
    position: relative;
    top: -50px;
    left: 0;
    width: 100%
  }

  /*alignement popup du modal de retour à la visualiation d'une offre prépubliée*/
  .mantine-xiucpp {
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) {
  /* logo du syndic superposéer la photo du caroussel */
  #syndic-logo-OffreDeTravaux {
    width: 40px;
    height: 40px;
    position: relative;
    top:-95px; right: -15px;
    padding: 5px 15px;
  }

  .noLogoOnPic {
    width: 40px;
    height: 40px;
    position: relative;
    top:-65px; right: -15px;
    padding: 5px 15px;
    border: 1px solid #ededed;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    color:#393939;
    font-size: 30px;
    font-weight: 400;
  }

  .redbox {
    width: 95%;
    padding: 10px 5px;
    background-color: #f8f8f8;
    border: solid 1px #CC4E3C;
    /* margin-left: 20px; */
    margin-top: 20px;
    border-radius: 4px;
  }

  .w400 {
    width: 400px;
  }

  .imgCarroussel {
    width: 100%;
    height: 390px;
    border: 1px solid #ebebeb;
  }

  .offer-first-lines-info {
    margin-left: 20px;
  }

  .followPosition {
    position: relative;
    top:-40px;
  }

  /*alignement popup du modal de retour à la visualiation d'une offre prépubliée*/
  .mantine-xiucpp {
    padding-left: 0;
  }

  /*alignement menu UX*/
  .menuDesktop {
    position: absolute;
    top : 7%;
    left: 72.5%
  }
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Offre de travaux > Consultation Devis d'une offre publiée >
> les prestataires favoris à qui transmettre l'offre*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width:600px) {
  .btn-transferAOffer {
    border: none;
    background-color: #000;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 12px;
    width: 100%;
  }

  .btn-offerTransfered {
    border: none;
    background-color: #1FA200;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 15px;
  }

  .btn-offerTransfer {
    border: none;
    background-color: #000;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 12px;
  }
}

@media screen and (min-width: 768px) {
  .btn-transferAOffer {
    border: none;
    background-color: #000;
    color: #ffffff;
    border-radius: 10px;
    padding: 8px 20px;
    font-size: 16px;
    margin-right: 20px;
  }

  .btn-offerTransfered {
    border: none;
    background-color: #1FA200;
    color: #ffffff;
    border-radius: 10px;
    padding: 8px 22px;
    font-size: 16px;
    margin-right: 20px;
  }

  .btn-offerTransfer {
    border: none;
    background-color: #000;
    color: #ffffff;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 16px;
    margin-right: 30px;
  }
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Offre de travaux > Consultation Devis d'une offre publiée*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width:600px) {

  /* texte du segmented control */
  .mantine-1wcc1kz {
    font-family: 'Roboto', sans-serif;
  }

  .mantine-8vuirw {
    font-family: 'Roboto', sans-serif;
  }

  .received-quotation-titles {
      width: 90%;
      font-size: 13px;
      font-weight: THIN;
      color: #616161; 
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase
    }

/* libélé de accordion mantine */
  .mantine-Accordion-label {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: THIN;
    color: #616161;
    text-transform: uppercase
  }

  /* texte des devis supprimés */
  .mantine-1supnlp {
    font-family: 'Roboto', sans-serif;
  }
  .btn-action-for-rdv {
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 8px 0 8px 0;
    width: 30%;
  }

  .btn-accepted-rdv {
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 8px 0 8px 0;
    width: 55%;
  }

  .btn-action-for-quot {
    margin-bottom: 15px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 8px 0 8px 0;
    width: 45%;
  }

  /*btn contact dans sendRdv*/
  .btn-action-contact-rdv {
    border: none;
    border-radius: 50px;
    color: #ffffff;
    padding: 5px;
    width: 40%;
  }
}



@media screen and (min-width: 768px) {

  /* texte du segmented control */
  .mantine-1wcc1kz {
    font-family: 'Roboto', sans-serif;
  }

  .mantine-8vuirw {
    font-family: 'Roboto', sans-serif;
  }

  .received-quotation-titles {
      width: 90%;
      font-size: 13px;
      font-weight: THIN;
      color: #616161; 
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase
    }

/* libélé de accordion mantine */
  .mantine-Accordion-label {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: THIN;
    color: #616161;
    text-transform: uppercase
  }

  /* texte des devis supprimés */
  .mantine-1supnlp {
    font-family: 'Roboto', sans-serif;
  }
  .btn-action-for-rdv {
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 0 10px 0;
    width: 110px;
    font-size: 16px;
  }

  .btn-accepted-rdv {
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 0 10px 0;
    width: 110px;
    font-size: 16px;
  }

  .btn-action-for-quot {
    margin-bottom: 25px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 0 10px 0;
    width: 160px;
    font-size: 16px;
  }

  /*btn contact dans sendRdv*/
  .btn-action-contact-rdv {
    border: none;
    border-radius: 50px;
    color: #ffffff;
    padding: 7px;
    width: 110px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
}


/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Offre de travaux > Consultation RDV d'une offre publiée > Envoi les info d'un RDV*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width:600px) {
  .send-RDV-info-titles {
    font-size: 13px;
    text-transform: uppercase;
    color: #616161; 
    margin-bottom: 10px;
    font-family:'Roboto', sans-serif;
    margin: 5px 0 ;
  }

  /*style l'accordion panel de la liste des rdv*/
  .mantine-731ldn {
    padding: inherit;
    font-family: 'Roboto', 'sans-serif';
  }

  .mantine-gm39d {
    padding-bottom: inherit;
    padding-top: 0.5rem;
  }

}

@media screen and (min-width: 768px) {
  .send-RDV-info-titles {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #616161; 
    margin-bottom: 10px;
    font-family:'Roboto', sans-serif;
    margin: 0 0 10px 20px;
  }

  /*style l'accordion panel de la liste des rdv*/
  .mantine-731ldn {
    padding: inherit;
    font-family: 'Roboto', 'sans-serif';
  }

  .mantine-gm39d {
    padding-bottom: inherit;
    padding-top: 0.5rem;
  }

  /*popup showcontact*/
  .mantine-kea9ny {
    background-color: rgba(0, 0, 0, 0.3);
  }

}

/*
########  ########  ########  ######  ########    ###    
##     ## ##     ## ##       ##    ##    ##      ## ##   
##     ## ##     ## ##       ##          ##     ##   ##  
########  ########  ######    ######     ##    ##     ## 
##        ##   ##   ##             ##    ##    ######### 
##        ##    ##  ##       ##    ##    ##    ##     ## 
##        ##     ## ########  ######     ##    ##     ## */

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Match */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
  .relativeP {
    position:relative;
    top: -50px;
    left: 0px;
  }

 

}


/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Devis > Info Devis */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width:600px) {
  .file-input-editQuot__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* btn Modifier devis */
  .mantine-ni0p3o {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
    color : #000;
    border: 1px solid #000;
   text-align: center;
  }

  /* texte du btn Modifier devis */
  .mantine-167ugbe {
    color: #000;
  } 

  .mantine-ukbbnm {
    padding: 1px 5px;
  }
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Profil > Page vitrine*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width:600px) {
  .prestapage-logo-container {
    position:relative;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 95px;
    width: 95px;
    border: 0;
  }

  .prestapage-logo-container img {
    height: 95px;
    width: 95px;
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    border: 0;
  }
  
  .prestapage-container hr {
    width: 100%;
    background-color: #F8F8F8;
    height: 2px;
    border: none;
  }

  /* section 2 */
 


  #prestapage-contacter-btn {
    font-size: 13px;
    border-radius: 50px;
    background-color: #000;
    color: #fff;
    padding: 5px 15px;
  }

  /* section 3, les indicateurs */

  .indicateurs h1 {
    font-size: 17px;
    margin: 0;
    text-align: center;
  }

  .indicateurs p {
    font-size: 11px;
    margin: 0;
    text-align: center;
  }

}

@media screen and (min-width: 768px) {
  .prestapage-container {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: #ececec solid 1px;
    padding : 20px;
    border-radius: 8px;
    margin-bottom: 100px;
  }
  .prestapage-logo-container {
    /* position:relative;
    margin: auto; */
    /* margin-bottom: 30px;
    margin-top: 30px; */
    height: 125px;
    width: 125px;
    border: 0;
    margin-right: 20px;
  }

  .prestapage-logo-container img {
    height: 95px;
    width: 95px;
    /* position:absolute;
    margin-left: auto;
    margin-right: auto; */
    border: 0;
  }
  
  .prestapage-container hr {
    width: 100%;
    background-color: #F8F8F8;
    height: 2px;
    border: none;
  }

  /* section 2 */
 


  #prestapage-contacter-btn {
    font-size: 13px;
    border-radius: 50px;
    background-color: #000;
    color: #fff;
    padding: 5px 15px;
  }

  /* section 3, les indicateurs */

  .indicateurs h1 {
    font-size: 20px;
    margin: 0;
    text-align: center;
  }

  .indicateurs p {
    font-size: 16px;
    margin: 0;
    text-align: center;
  }

}

/*
##     ## ##     ## ######## ##     ##    ###    ##       
###   ### ##     ##    ##    ##     ##   ## ##   ##       
#### #### ##     ##    ##    ##     ##  ##   ##  ##       
## ### ## ##     ##    ##    ##     ## ##     ## ##       
##     ## ##     ##    ##    ##     ## ######### ##       
##     ## ##     ##    ##    ##     ## ##     ## ##       
##     ##  #######     ##     #######  ##     ## ########*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Not found company pour l'ajout d'un presta favoris pour un syndic et la recommandation d'une offre à un confrère pour le presta */
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
  .inviteNotFoundPrestToJoinBtn {
    border: none;
    color: #ffffff;
    border-radius: 50px;
    padding: 5px 30px;
  }
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/* Onglet Profil*/
/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/

@media screen and (max-width: 600px) {
  .profil-menu p {
    margin: 5px 0 5px 5px;
    font-size: 15px;
  }

  .profil-menu hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: #EBEBEB;
  }

  #versionApp {
    font-size: 10px;
    margin-top: 5px;
    text-align: center;
  }

  .noAvatarProfile {
    width: 70px; height: 70px;
    line-height: 70px; 
    border-radius: 50px;
    margin: 0px ;
    border: 1px solid #ededed;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    color:#7d7d7d;
    font-size: 30px;
    font-weight: 400;
  }

  .paddingMenuProfil {
    margin: 7px 10px;
  }
}

@media screen and (min-width: 768px) {
  .profil-menu p {
    margin: 5px 0 5px 5px;
    font-size: 18px;
  }

  .profil-menu hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: #EBEBEB;
  }

  #versionApp {
    font-size: 15px;
    margin-top: 5px;
    text-align: center;
  }

  .noAvatarProfile {
    width: 70px; height: 70px;
    line-height: 70px; 
    border-radius: 50px;
    margin: 0px ;
    border: 1px solid #ededed;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    color:#7d7d7d;
    font-size: 30px;
    font-weight: 400;
  }

  .paddingMenuProfil {
    margin: 7px 10px;
  }
}

@media screen and (min-width: 768px) {
  .noAvatarProfile {
    width: 110px; height: 110px;
    line-height: 70px; 
    border-radius: 100px;
    margin: 0px ;
    border: 1px solid #ededed;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    color:#7d7d7d;
    font-size: 40px;
    font-weight: 600;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
}
