@media screen and (max-width:600px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;        
    }

    .Logo img {
        width: 285px;
        height: 170px;
        margin: -55px -60px -70px -55px;
        
    }
}

@media screen and (min-width: 768px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;
        /* border: 1px solid #ddd; */
        
    }

    .Logo img {
        width: 481px;
        height: 287px;
        margin: -90px -60px -70px -90px;
        
    }

}