@media screen and (max-width:600px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
    }

    .navbackBlank {
      width: 20px;
      background-color: #ffffff;
    }
    
    .navtitle h2 {
      font-size: 15px;
      width:180px;
      text-align: center;
    }

    .navrightsideCloseIcon {
        height: 20px;
        width: 20px;
      }
    
      .btn{
        border: none;
        background-color: #ffffff;
      }
  }

@media screen and (min-width: 768px) {
    .appHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        padding-top: 35px;
        padding-bottom: 15px;
      }
  
      .navbackBlank {
        width: 20px;
        background-color: #ffffff;
      }
      
      .navtitle h2 {
        font-size: 20px;
        text-align: center;
      }
  
      .navrightsideCloseIcon {
          height: 20px;
          width: 20px;
        }
      
        .btn{
          border: none;
          background-color: #ffffff;
        }
    }