@media screen and (max-width:600px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
    }

  .navback {
      display: flex;
      align-items: center;
    }

  .btnResetNavback {
      border: none;
      background-color: #ffffff;
      font-size: 11px;
      padding-left: 0;
  }

  .navbackIcon {
      height: 11px;
      width: 11px;
    }

  .navback a {
    text-decoration: none;
    color: #000;
  }

  .navtitle h1 {
    font-size: 15px;
    width:200px;
    text-align: center;
  }

  .navrightsideBlank {
    height: 30px;
    width: 30px;
    background-color: #ffffff;
  }

  .txt {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
    }

  .navback {
      display: flex;
      align-items: center;
    }

  .btnResetNavback {
      border: none;
      background-color: #ffffff;
      font-size: 15px;
      padding-left: 0;
  }

  .navbackIcon {
      height: 11px;
      width: 11px;
    }

  .navback a {
    text-decoration: none;
    color: #000;
  }

  .navtitle h1 {
    font-size: 20px;
    text-align: center;
  }

  .navrightsideBlank {
    height: 30px;
    width: 30px;
    background-color: #ffffff;
  }

  .txt {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}