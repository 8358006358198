@media screen and (max-width:600px) {
  .appHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 35px;
      padding-bottom: 15px;
  }

  .navback {
    display: flex;
    align-items: center;
  }

  .navtitle h1 {
    font-size: 15px;
    text-align: center;
    width: 250px;

  }

  .navrightside {
    height: 30px;
    width: 30px;
  }

  .btnReset {
    border: none;
    background-color: #ffffff;
  }

.btnResetNavback {
    border: none;
    background-color: #ffffff;
    font-size: 11px;
    padding-left: 0;
}

}

@media screen and (min-width: 768px) {
  .appHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 35px;
    padding-bottom: 15px;
}

  .navback {
    display: flex;
  }

.navtitle h1 {
  font-size: 20px;
  text-align: center;

}

  .navrightside {
    height: 30px;
    width: 30px;
    margin-right: 30px;
  }

.btnReset {
  border: none;
  background-color: #ffffff;
}

.btnResetNavback {
  border: none;
  background-color: #ffffff;
  font-size: 16px;
  padding-left: 0;
}

}