@media screen and (max-width:600px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;
        /* border: 1px solid #ddd; */
        
    }

    .Logo img {
        width: 290px;
        height: 175px;
        margin: -55px -70px -70px -75px;
    }
}

@media screen and (min-width: 768px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;      
    }

    .Logo img {
        width: 490px;
        height: 275px;
        margin: -55px -70px -70px -75px;
    }
}

