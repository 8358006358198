@media screen and (max-width:600px) {
  .navtitle {
      font-size: 15px;
      text-align: center;
      margin: 0;
      padding: 60px 0px 20px 0px;
    }
}

@media screen and (min-width: 768px) {
  .navtitle {
      font-size: 20px;
      text-align: center;
      margin: 0;
      padding: 60px 0px 20px 0px;
    }
}