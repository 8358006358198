@media screen and (max-width:600px) {
.Logo {
    background-color: #fff;
    overflow: hidden;
    display: inline-block;
    /* border: 1px solid #ddd; */
    
}

.Logo img {
    width: 60px;
    height: 40px;
    border-radius: 5px;
    border-bottom: 2px solid rgb(0, 170, 255);
}
}

@media screen and (min-width: 768px) {
    .Logo {
        background-color: #fff;
        overflow: hidden;
        display: inline-block;        
    }
    
    .Logo img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
    }
    }