@media screen and (max-width:600px) {
  .actionBtn {
    border: none;
    background-color: #CC4E3C;
    color: #FFFFFF;
    width: 100%;
    height: 30px;
    font-size: 15px;
    border-radius: 100px;
  }
}

@media screen and (min-width: 768px) {
  .actionBtn {
    border: none;
    background-color: #CC4E3C;
    color: #FFFFFF;
    width: 100%;
    height: 45px;
    font-size: 16px;
    border-radius: 100px;
  }
}