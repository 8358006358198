@media screen and (max-width:600px) {
.sRoundButton {
    font-size: 13px;
    width: 100%;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    color: #FF0000;
    border: none;
  }
}

@media screen and (min-width: 768px) {
  .sRoundButton {
      font-size: 18px;
      width: 100%;
      height: 45px;
      font-family: 'Roboto', sans-serif;
      background-color: #ffffff;
      color: #FF0000;
      border: none;
    }
  }