@media screen and (max-width:600px) {
.container {
    width: 98%;
    padding: 10px 0;
    background-color: #f8f8f8;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
  }
}

@media screen and (min-width: 768px) {
  .container {
      width: 100%;
      padding: 10px 0;
      background-color: #f8f8f8;
      margin-left: auto;
      margin-right: auto;
      border-radius: 6px;
    }
  }