@media screen and (max-width:600px) {
  .actionBtn {
    border: none;
    background-color: #F8F8F8 ;
    color: #A1A1A1;
    width: 100%;
    height: 30px;
    font-size: 15px;
    border-radius: 100px;
  }
}

@media screen and (min-width: 768px) {
  .actionBtn {
    border: none;
    background-color: #F8F8F8 ;
    color: #A1A1A1;
    width: 100%;
    height: 45px;
    font-size: 15px;
    border-radius: 100px;
  }
}